<template>
    <div>
        <transition name="fade">
        <LoginForm v-if="showLogin" />
        <posLoginForm v-else />
        </transition>
    </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
import posLoginForm from "../components/posLoginForm.vue";
export default{
    components: {LoginForm,posLoginForm},
    data() {
        return {
            
        }
    },
    
    computed: {
        showLogin: function (){
            // return (this.$store.state.licenseType.POSREST == false && !this.$store.state.licenseType.POSRET ==false)
            if(this.$store.state.licenseType.POSREST && !this.$store.state.licenseType.POSRET){
                return false;
            }
            return true;
        },
        lang: {
            get() {
                if(this.$cookies.get(this.$store.state.LangCooki) != null){ 
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set(lang){
                this.$store.state.deflang = lang;
                if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
        },
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
