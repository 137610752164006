<template>
  <div class="body" style="direction: rtl;">
    <v-row>
      <v-col cols="12" md="6">
        <div class="mt-10 pa-10 text-center">
            <div class="errorMesg" v-if="showerr">اسم المستخدم او كلمة المرور غير صحيحة يرجى المحاولة مره اخرى</div>
            <div class="mt-16" :style="`border-radius:10px;color:#FFF;background:rgb(0, 0, 0);margin:auto;direction: `+this.lang.dir+`;width:300px;border:1px solid #000`">
              <div class="text-start pa-3">
                <label for="input-live">{{lang.login_name}}</label>
                  <b-form-input
                    id="username"
                    v-model="username"
                    @keyup.enter="enterlogin()"
                    aria-describedby="input-live-help input-live-feedback"
                    :placeholder="lang.login_name"
                    @keyup.native.enter="doLogin()"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!nameState" id="input-live-feedback">
                    {{lang.required_field}}
                  </b-form-invalid-feedback>
              </div>
              <div class="text-start pa-3 pt-0">
                <label for="input-live">{{lang.password}}</label>
                  <b-form-input
                    id="password"
                    v-model="password"
                    @keyup.enter="enterlogin()"
                    aria-describedby="input-live-help input-live-feedback"
                    :placeholder="lang.password"
                    @keyup.native.enter="doLogin()"
                    type="password"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!passState" id="input-live-feedback">
                    {{lang.required_field}}
                  </b-form-invalid-feedback>
              </div>
              <div class="pa-3 pt-0" style="display:flex;flex-direction:row;justify-content:space-between;flex-wrap:wrap" v-if="branches.length > 1">
                  <div class="text-start" style="direction:rtl;width:50%"  v-for="(item , index) in branches" :key="index">
                    <button class="btn btn-success" :id="`branch${item.id}`" v-if="index == 0" @click="changeBranch(item.id)" style="font-size:1rem;border:1px solid #000;background:#84c8ff;width:100%;border-radius:0;color:#000 !mportant;font-weight:bolder">{{ item[`name_${lang.langname}`] }}</button>
                    <button class="btn btn-success" :id="`branch${item.id}`" v-else @click="changeBranch(item.id)" style="font-size:1rem;border:1px solid #000;background:#eee;color:#000;width:100%;border-radius:0;font-weight:bolder">{{ item[`name_${lang.langname}`] }}</button>
                  </div>
              </div>
              <div class="pa-3 pt-0" style="display:flex;flex-direction:row;justify-content:space-between;flex-wrap:wrap" v-if="financeYear.length > 1">
                  <div class="text-start" style="direction:rtl;width:100%">
                    <b-form-select class="selborder" @change="setFinanceYear()" v-model="fYear" :options="FinYears" />
                  </div>
              </div>

              <div class="text-start pa-3 pt-0" style="direction:rtl">
                  <button class="btn btn-success" id="arabic" @click="changeLang('ar','ar')" style="border:1px solid #000;background:#84c8ff;width:50%;border-radius:0;color:#000 !mportant;font-weight:bolder">عربي</button>
                  <button class="btn btn-success" id="english" @click="changeLang('en','en')" style="border:1px solid #000;background:#eee;color:#000;width:50%;border-radius:0;font-weight:bolder">English</button>
                  <button class="btn btn-success" id="urdu" v-if="$store.state.licenseType.activemenu=='xsmall'" @click="changeLang('en','urdu')" style="border:1px solid #000;background:#eee;color:#000;width:50%;border-radius:0;font-weight:bolder">أوردو</button>
              </div>
              <div style="margin:20px 5px">
                <b-button @click="doLogin()" type="button" class="bgbalck" style="font-size: small;width:50%;background:#84c8ff !important;color:#000 !important;font-weight:bolder"> {{loginname}}&nbsp;	&nbsp;	<i class="fas fa-sign-in-alt"></i></b-button>
              </div>
              
            </div>
            <div class="text-center" style="color:black">SAMCOTEC V {{$store.state.Version}}</div>
            <div class="loginSupport">{{ lang.for_more_information_or_support_firstline }}</div>
            <div class="loginSupport">{{ lang.for_more_information_or_support_secondline }}</div>
        </div>
        </v-col>
        <v-col style="position:relative;background:#fff;color:#000;height:100vh;margin-top:-3px;border-bottom:3px solid #84c8ff;border-top:3px solid #84c8ff;border-right:3px solid #000;border-left:3px solid #84c8ff" cols="12" md="6">
          <div class="pa-5 mt-16 text-center" style="padding-top:25% !important">
            <img :src="`../img/samcotec-logo.png`" v-if="!$store.state.licenseType.cars" style="width:130px;">
            <img :src="`../img/${$store.state.logo}`" v-else style="width:130px;">
          </div>
          <div class="pa-5 mt-16 text-center" style="padding-top:10px !important">
              <img :src="`../img/safip.png`" style="width:200px" />
              <div style="margin-top:10px;text-align: center;">
                <div style="margin-top:10px;text-align: center;"><span style="color:red">تحذيـر </span> 
                  يمنع إعادة إنتاج البرنامج وملحقاته بشكل كامـل أو تقليـده دون زيـادة أو نقصان أو أجراء أي تعديل عليه 
                  هذا المنتج تملكة شركة سام لتقنية المعلومات المحدوده – الرياض بموجب العلامه التجارية SAMCOTEC
                  
                </div>
                <div style="margin-top:10px;text-align: center;">
                   باعتماد الهيئة السعودية للملكية الفكرية برقم: 1435022395 وتاريخ 05-01-1436
                </div>
              </div>
              <div class="loginSupport" style="display:none;"><br />{{ lang.for_more_information_or_support }}</div>
          </div>
        </v-col>
    </v-row>
    <showMessage :message="SamMessage" :clientStatus="clientStatus" v-if="showStartUpMessage" />
  </div>
</template>

<script>
import axios from 'axios'
import showMessage from '@/components/showMessage.vue'
export default {
  components: {showMessage},
  data() {
    return {
      showStartUpMessage: false,
      showerr: false,
      username: '',
      password: '',
      loginname: 'تسجيل دخول',
      SamMessage: '',
      clientStatus: '',
      langname: "ar",
      branches: [],
      defBranch: 1,
      financeYear: [],
      fYear: (new Date()).getFullYear()
    }
  },
  computed: {
    nameState() {
      return this.username.length > 2 ? true : false
    },
    passState() {
      return this.password.length > 2 ? true : false
    },
    lang: function(){
      
      let lang = this.$store.state.lang.ar;
      if(this.langname == "en"){
        lang = this.$store.state.lang.en;
      }else{
        lang = this.$store.state.lang.ar;
      }
      return lang;
    },
    FinYears: function(){
      let t = [];
      for(let i=0;i<this.financeYear.length;i++){
        t.push({text: this.financeYear[i], value: this.financeYear[i]})
      }
      return t;
    }
  },
  beforeCreate() {
    //this.getStart();
  },
  created() {
    setTimeout(() => {
      this.changeLang(this.$store.state.deflang,this.$store.state.deflang);
    },1000);
    this.getStart();
    localStorage.setItem('defBranch',1);
    localStorage.setItem('financeYear',(new Date()).getFullYear());
    //document.addEventListener('keypress', this.enterlogin);
  },
  methods: {
    setFinanceYear(){
      localStorage.setItem('financeYear',this.fYear);
    },
    changeBranch(id){
      // // console.log(id);
      for(let i=0;i<this.branches.length;i++){
        if(this.branches[i].id == id){
          // document.getElementById('branch'+id).style.background = '#d7b100';
          document.getElementById('branch'+id).style.background = '#84c8ff';
          document.getElementById('branch'+id).style.color = '#000';
        }else{
          document.getElementById('branch'+this.branches[i].id).style.background = '#eeeeee';
          document.getElementById('branch'+this.branches[i].id).style.color = '#000000';
        }
      }
      this.defBranch = id;
      localStorage.setItem('defBranch',id);
    },
    changeLang(id,sub) {
      console.log([id,sub]);
      if(id != "ar" && id != "en"){
        id = "ar";
      }
      console.log(this.$store.state.LangCooki);
      this.$cookies.set(this.$store.state.LangCooki, id)
      if(id == "ar" && sub == "ar") {
        // document.getElementById('arabic').style.background = '#84c8ff';
        document.getElementById('arabic').style.background = '#84c8ff';
        document.getElementById('arabic').style.color = '#000';
        document.getElementById('english').style.background = '#eeeeee';
        document.getElementById('english').style.color = '#000000';
        document.getElementById('urdu').style.background = '#eeeeee';
        document.getElementById('urdu').style.color = '#000000';
      }else if(id == "en" && sub == "en"){
        document.getElementById('arabic').style.background = '#eeeeee';
        document.getElementById('arabic').style.color = '#000000';
        document.getElementById('english').style.background = '#84c8ff';
        document.getElementById('english').style.color = '#000';
        document.getElementById('urdu').style.background = '#eeeeee';
        document.getElementById('urdu').style.color = '#000000';
      }else if(id == "en" && sub == "urdu"){
        document.getElementById('arabic').style.background = '#eeeeee';
        document.getElementById('arabic').style.color = '#000000';
        document.getElementById('english').style.background = '#eeeeee';
        document.getElementById('english').style.color = '#000';
        document.getElementById('urdu').style.background = '#84c8ff';
        document.getElementById('urdu').style.color = '#000000';
      }
      this.langname = id;
      this.$store.state.deflang = id
      this.$cookies.set(this.$store.state.LangCooki, id)
      localStorage.setItem("subLang", sub)
      // if(id == "ar"){
      //   document.getElementById('arabic').style.background = "#84c8ff";
      //   document.getElementById('arabic').style.color = "#000";
      //   document.getElementById('english').style.background = "#eee";
      //   document.getElementById('english').style.color = "#000";
      // }else{
      //   document.getElementById('english').style.background = "#84c8ff";
      //   document.getElementById('english').style.color = "#000";
      //   document.getElementById('arabic').style.background = "#eee";
      //   document.getElementById('arabic').style.color = "#000";
      // }
      // this.$store.state.deflang = id
      // this.$cookies.set(this.$store.state.LangCooki, id)
      // if(id == 'en'){
      //   this.$parent.lang = this.$store.state.lang.en;
      //   this.lang.dir = "ltr";
      //   this.lang.ldir = "rtl";
      //   this.lang.login_name = "Username";
      //   this.lang.password = "Password";
      //   this.lang.save_login_info = "Save login info";
      //   this.loginname = "Login"
      //   this.$parent.for_more_information_or_support_firstline = " 1For support, or any inquires please contat us"
      //   this.$parent.for_more_information_or_support_secondline = "0566566160 - 0595907777 - 0565100268"
      // }else{
      //   this.$parent.lang = this.$store.state.lang.ar;
      //   this.lang.dir = "rtl";
      //   this.lang.ldir = "ltr";
      //   this.lang.login_name = "اسم الدخول";
      //   this.lang.password = "كلمة المرور";
      //   this.lang.save_login_info = "حفظ بيانات الدخول";
      //   this.loginname = "تسجيل الدخول"
      //   this.$parent.for_more_information_or_support_firstline = "للدعم الفني الاتصال"
      //   this.$parent.for_more_information_or_support_secondline = "0566566160 - 0595907777 - 0565100268"
      // }
    },
    enterlogin(){
      // e.preventDefault();
      // if (event.keyCode == 13){
        // if(this.username == '') {
        //   document.getElementById('username').focus();
        //   return false;
        // }
        // if(this.password == '' && this.username != '') {
        //   document.getElementById('password').focus();
        //   return false;
        // }
        // this.doLogin();
     
    },
    getStart(){ 
      let pp = new FormData();
      pp.append('defBranch',localStorage.getItem('defBranch'));
      pp.append('financeYear',localStorage.getItem('financeYear'));
      let type = 'getMeTodo';

      pp.append('type',type);
      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
        // // console.log(response.data);
        this.$store.state.loginRedirect = response.data.results.redirectURl;
        localStorage.setItem('redirectURl',response.data.results.redirectURl);
        this.branches = response.data.results.branches
        this.financeYear = response.data.results.financeYear
        localStorage.setItem('_ECar_',JSON.stringify(response.data.results.data));
        this.$store.commit('setLicenseType',response.data.results.data);
      })
      
    },
    doLogin(){

      let pp = new FormData();
      pp.append('defBranch',localStorage.getItem('defBranch'));
      pp.append('financeYear',localStorage.getItem('financeYear'));
      let type = 'register';

      pp.append('data[username]',this.username);
      pp.append('data[password]',this.password);
      pp.append('type',type);
      pp.append('data[defBranch]',this.defBranch);
      pp.append('defBranch',this.defBranch);

      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
          
          let res = response.data;
          if(res.error.number == 200){
              this.$store.state.userinfo = res.results.data;
              this.ip = "DM3-CMS-M33-103-650";
              let code = res.results.data.ID+'||'+res.results.hash+'||'+this.ip+'||'+res.results.time+'||'+this.$cookies.get(this.$store.state.LangCooki)+'||'+this.defBranch;
              this.$cookies.set(this.$store.state.COOKIEPhase,code,86400);
              this.$store.state.group_id = res.results.data.group_id;
              this.$store.state.loginRedirect = res.results.data.redirectURl;
            if(res.results.data.group_id == 9){
              this.$store.state.loginRedirect = "/clientList-mobile";
              this.$router.push({path: this.$store.getters['state'].loginRedirect})
            }
            else if(res.results.data.message.showNumber >= 0 && res.results.data.message.showNumber < 4){
              this.showStartUpMessage = true;
              this.SamMessage = res.results.data.message.message;
              this.clientStatus = res.results.data.message.clientStatus;
            }
            else{
              localStorage.setItem('currentMenu',1)
              this.$router.push({path: this.$store.getters['state'].loginRedirect})
              // this.$router.push({path: "/POS-RETAIL"})
            }
          }else{
            this.showerr = true;
          }
      });
    }
  },
}
</script>
<style>
.body{
  border:3px inset #000;
  background:#84c8ff !important;
  color:#000;
  height: 100vh; 
}
.bgbalck{
  background:rgb(0,0,0) !important;
  color:#FFF !important;
}
.errorMesg{
  background:rgba(0, 0, 0,0.5);
  padding:5px;
  width:50%;
  margin:auto;
  border-radius: .8rem;
}
.loginSupport{
  z-index:4000;
  /* position: absolute; */
  margin-top:10px;
  bottom: 0px;
  left: 0;
  right:0;
  padding:5px;
  text-align:center;
  font-size:1.2rem;
  /* background:#000; */
  color:#000;
}
</style>