<template>
  <div class="body" style="direction: rtl;">
    <v-row>
      <v-col cols="12" md="6">
        <div class=" pa-10 text-center" style="margin-top:10rem">
            <!-- <img :src="`../img/samcotec-logo.png`" style="width:150;"> -->
            <div class="errorMesg" style="width:250px;" v-if="showerr">اسم المستخدم او كلمة المرور غير صحيحة يرجى المحاولة مره اخرى</div>
            <div class="mt-1" :style="`border-radius:10px;color:#FFF;background:rgb(0, 0, 0);margin:auto;direction: `+this.lang.dir+`;width:250px;border:1px solid #000`">
              <div class="text-start" style="padding-inline-start:10px;padding-inline-end:10px;">
                <label for="input-live">{{lang.login_name}}</label>
                  <b-form-input
                    id="username"
                    v-model="username"
                    @keyup.enter="enterlogin()"
                    aria-describedby="input-live-help input-live-feedback"
                    :placeholder="lang.login_name"
                    @click="setFocus('username')"
                    @keyup.native.enter="doLogin()"
                    style="padding-top:3px;padding-bottom:3px;font-size:.9rem"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!nameState" id="input-live-feedback">
                    {{lang.required_field}}
                  </b-form-invalid-feedback>
              </div>
              <div class="text-start" style="padding-inline-start:10px;padding-inline-end:10px;">
                <label for="input-live">{{lang.password}}</label>
                  <b-form-input
                    id="password"
                    v-model="password"
                    @click="setFocus('password')"
                    @keyup.enter="enterlogin()"
                    aria-describedby="input-live-help input-live-feedback"
                    :placeholder="lang.password"
                    @keyup.native.enter="doLogin()"
                    style="padding-top:3px;padding-bottom:3px;font-size:.9rem"
                    type="password"
                    trim
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!passState" id="input-live-feedback">
                    {{lang.required_field}}
                  </b-form-invalid-feedback>
              </div>
              <div class="text-start pa-3 pt-0" style="direction:rtl;display:none;">
                  <button class="btn btn-success" style="border:1px solid #000;background:#d7b100;width:50%;border-radius:0;color:#FFF !mportant;font-weight:bolder">عربي</button>
                  <button class="btn btn-success" style="border:1px solid #000;background:#eee;color:#000;width:50%;border-radius:0;font-weight:bolder">English</button>
              </div>
              <div class="text-start pa-3 pt-0 mt-2" style="direction:rtl">
                    <div class="bottomArea">
                        <div class="loginBtn" @click="addThisNum(1)">1</div>
                        <div class="loginBtn" @click="addThisNum(2)">2</div>
                        <div class="loginBtn" @click="addThisNum(3)">3</div>
                    </div>
                    <div class="bottomArea">
                        <div class="loginBtn" @click="addThisNum(4)">4</div>
                        <div class="loginBtn" @click="addThisNum(5)">5</div>
                        <div class="loginBtn" @click="addThisNum(6)">6</div>
                    </div>
                    <div class="bottomArea">
                        <div class="loginBtn" @click="addThisNum(7)">7</div>
                        <div class="loginBtn" @click="addThisNum(8)">8</div>
                        <div class="loginBtn" @click="addThisNum(9)">9</div>
                    </div>
                    <div class="bottomArea">
                        <div class="loginBtn" v-if="langname == 'en'" ref="arabic" style="background:#d7b100;color:#000 !important;font-weight:bolder" id="arabic" @click="changeLang('ar')">ع</div>
                        <div class="loginBtn" v-else ref="english" id="english" @click="changeLang('en')">E</div>
                        <div class="loginBtn" @click="addThisNum(0)">0</div>
                        <div class="loginBtn" @click="packSpace()"><img :src="`img/backspace.png`" style="width:24px" /></div>
                    </div>
              </div>
              <div style="margin:5px 5px">
                <b-button @click="doLogin()" type="button" class="bgbalck" style="font-size: small;width:75%;background:#d7b100 !important;color:#000 !important;font-weight:bolder"> {{loginname}}&nbsp;	&nbsp;	<i class="fas fa-sign-in-alt"></i></b-button>
              </div>
              
            </div>
            <div class="text-center" style="color:black">SAMCOTEC V {{$store.state.Version}}</div>
            <div class="loginSupport">{{ lang.for_more_information_or_support_firstline }}</div>
            <div class="loginSupport">{{ lang.for_more_information_or_support_secondline }}</div>
        </div>
        </v-col>
        <v-col style="position:relative;background:#fff;color:#000;height:100vh;margin-top:-3px;border-bottom:3px solid #d7b100;border-top:3px solid #d7b100;border-right:3px solid #000;border-left:3px solid #d7b100" cols="12" md="6">
          <div class="pa-5 mt-16 text-center" style="padding-top:25% !important">
            <img :src="`../img/samcotec-logo.png`" v-if="!$store.state.licenseType.cars" style="width:150;">
            <img :src="`../img/new-logo.png`" v-else style="width:150;">
          </div>
          <div class="pa-5 mt-16 text-center" style="padding-top:10px !important">
              <img :src="`../img/safip.png`" style="width:200px" />
              <div style="margin-top:10px;text-align: center;">
                <div style="margin-top:10px;text-align: center;"><span style="color:red">تحذيـر </span> 
                  يمنع إعادة إنتاج البرنامج وملحقاته بشكل كامـل أو تقليـده دون زيـادة أو نقصان أو أجراء أي تعديل عليه 
                  هذا المنتج تملكة شركة سام لتقنية المعلومات المحدوده – الرياض بموجب العلامه التجارية SAMCOTEC
                  
                </div>
                <div style="margin-top:10px;text-align: center;">
                   باعتماد الهيئة السعودية للملكية الفكرية برقم: 1435022395 وتاريخ 05-01-1436
                </div>
              </div>
              <div class="loginSupport" style="display:none;"><br />{{ lang.for_more_information_or_support }}</div>
          </div>
        </v-col>
    </v-row>
    <showMessage :message="SamMessage" :clientStatus="clientStatus" v-if="showStartUpMessage" />
  </div>
</template>

<script>
import axios from 'axios'
import showMessage from '@/components/showMessage.vue'
export default {
  components: {showMessage},
  data() {
    return {
      showStartUpMessage: false,
      showerr: false,
      username: '',
      password: '',
      loginname: 'تسجيل دخول',
      SamMessage: '',
      clientStatus: '',
      langname: "ar",
      focus: 'username',
    }
  },
  computed: {
    nameState() {
      return this.username.length > 2 ? true : false
    },
    passState() {
      return this.password.length > 2 ? true : false
    },
    lang: function(){
      
      let lang = this.$store.state.lang.ar;
      if(this.langname == "en"){
        lang = this.$store.state.lang.en;
      }else{
        lang = this.$store.state.lang.ar;
      }
      return lang;
    }
  },
  created() {
    setTimeout(() => {
      this.changeLang(this.$store.state.deflang);
    },1000);
    this.getStart();
    //document.addEventListener('keypress', this.enterlogin);
  },
  methods: {
    packSpace(){
        this[this.focus] = this[this.focus].substring(0, this[this.focus].length - 1);
    },
    setFocus(id){
        this.focus = id;
    },
    addThisNum(id){
        this[this.focus] = this[this.focus] + id;
    },
    changeLang(id) {
      if(id != "ar" && id != "en"){
        id = "ar";
      }
      this.langname = id;
      this.$store.state.deflang = id
      this.$cookies.set(this.$store.state.LangCooki, id)
      if(id=="ar"){
        this.$refs.arabic.style.background = '#d7b100';
        this.$refs.english.style.background = '#ccc';
      }else{
        this.$refs.arabic.style.background = '#ccc';
        this.$refs.english.style.background = '#d7b100';
      }
      // console.log(this.$refs['english'].style.background)
    },
    enterlogin(){
      // e.preventDefault();
      // if (event.keyCode == 13){
        // if(this.username == '') {
        //   document.getElementById('username').focus();
        //   return false;
        // }
        // if(this.password == '' && this.username != '') {
        //   document.getElementById('password').focus();
        //   return false;
        // }
        // this.doLogin();
     
    },
    getStart(){
      let pp = new FormData();
      pp.append('defBranch',localStorage.getItem('defBranch'));
      pp.append('financeYear',localStorage.getItem('financeYear'));
      let type = 'getMeTodo';

      pp.append('type',type);
      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
          this.$store.commit('setLicenseType',response.data.results.data);
          this.$store.state.loginRedirect = response.data.results.redirectURl;
          localStorage.setItem('_ECar_',JSON.stringify(response.data.results.data));
          localStorage.setItem('redirectURl',response.data.results.redirectURl);
      })
      
    },
    doLogin(){

      let pp = new FormData();
      pp.append('defBranch',localStorage.getItem('defBranch'));
      pp.append('financeYear',localStorage.getItem('financeYear'));
      let type = 'register';

      pp.append('data[username]',this.username);
      pp.append('data[password]',this.password);
      pp.append('type',type);

      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
          
          let res = response.data;
          if(res.error.number == 200){
              this.$store.state.userinfo = res.results.data;
              let code = res.results.data.ID+'||'+res.results.hash+'||'+this.ip+'||'+res.results.time+'||'+this.$cookies.get(this.$store.state.LangCooki);
              this.$cookies.set(this.$store.state.COOKIEPhase,code,86400);
              this.$store.state.group_id = res.results.data.group_id;
              this.$store.state.loginRedirect = res.results.data.redirectURl;

            if(res.results.data.message.showNumber >= 0 && res.results.data.message.showNumber < 4){
              this.showStartUpMessage = true;
              this.SamMessage = res.results.data.message.message;
              this.clientStatus = res.results.data.message.clientStatus;
            }
            else{
              this.$router.push({path: "/POS-RETAIL"})
              localStorage.setItem('currentMenu',1)
              // this.$router.push({path: this.$store.getters['state'].loginRedirect})
              
            }
          }else{
            this.showerr = true;
          }
      });
    }
  },
}
</script>
<style>
.body{
  border:3px inset #000;
  background:#84c8ff !important;
  color:#FFF;
  height: 100vh;
}
.bgbalck{
  background:rgb(0,0,0) !important;
  color:#FFF !important;
}
.errorMesg{
  background:rgba(0, 0, 0,0.5);
  padding:5px;
  width:50%;
  border-radius: .8rem;
}
.loginSupport{
  z-index:4000;
  /* position: absolute; */
  margin-top:10px;
  bottom: 0px;
  left: 0;
  right:0;
  padding:5px;
  text-align:center;
  font-size:1.2rem;
  /* background:#000; */
  color:#000;
}
.bottomArea{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}
.loginBtn{
    color:#000;
    width:50px;
    padding:10px;
    border-radius: 25%;
    background:#ccc;
    border:2px solid #fff;
    text-align:center;
    margin-bottom:5px;
    margin-inline-end:5px;
    font-size:1.2rem;
}
</style>